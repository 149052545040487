
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiContainer-root {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.MuiToolbar-root{
  min-height: 42px !important;
}

.active-indicator::after{
  content: '';
  position: absolute;
  bottom: 0;
  width: 12px;
  height: 3px;
  border-radius: 3px;
  margin-bottom: -1px;
  box-sizing: border-box;
}
header{
   border:'unset' !important;
}
.MuiDrawer-docked .MuiPaper-root{
  border: none;
}

.no-scroll {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  overflow: hidden;
}
.no-scroll::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}